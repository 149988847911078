import React, { useEffect } from "react";

const AdComponent = ({ adClient, adSlot, id }) => {
  useEffect(() => {
    const checkAdRendered = () => {
      const adWrapper = document.querySelector(`.${id}`);
      const adElement = adWrapper.querySelector(".adsbygoogle");

      if (adElement) {
        const iframe = adElement.querySelector("iframe");
        if (!iframe) {
          // If the iframe is not present, add the 'none' class to the wrapper
          adWrapper.classList.add("none");
        } else {
          // Remove the 'none' class if the iframe is present (ad rendered successfully)
          adWrapper.classList.remove("none");
        }
      }
    };

    const initializeAds = () => {
      const adElement = document.querySelector(`.${id} .adsbygoogle`);

      if (adElement) {
        const adStatus = adElement.getAttribute("data-ad-status");

        if (adStatus === "unfilled" || !adStatus) {
          adElement.remove();

          const newAdElement = document.createElement("ins");
          newAdElement.className = "adsbygoogle";
          newAdElement.style.display = "inline-block";
          newAdElement.style.width = "250px";
          newAdElement.style.height = "250px";
          newAdElement.setAttribute("data-ad-client", adClient);
          newAdElement.setAttribute("data-ad-slot", adSlot);

          const wrapper = document.querySelector(`.${id}`);
          if (wrapper) {
            wrapper.appendChild(newAdElement);
          }

          try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
          } catch (error) {
            console.error("AdSense initialization error:", error);
          }
        } else {
          console.log("Ad is already filled and active.");
        }
      }

      // Check if the ad was rendered correctly after initialization
      setTimeout(checkAdRendered, 5000); // Wait 1 second to allow ad to load
    };

    const loadAdScript = () => {
      const existingScript = document.querySelector(
        'script[src*="pagead/js/adsbygoogle.js"]'
      );
      if (!existingScript) {
        const script = document.createElement("script");
        script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${adClient}`;
        script.async = true;
        script.crossOrigin = "anonymous";
        document.body.appendChild(script);
        script.addEventListener("load", initializeAds);
      } else {
        initializeAds();
      }
    };

    loadAdScript();

    // Cleanup: Optionally remove the script if necessary
    return () => {
      const scriptToRemove = document.querySelector(
        'script[src*="pagead/js/adsbygoogle.js"]'
      );
      if (scriptToRemove) {
        scriptToRemove.remove();
      }
    };
  }, [adClient, adSlot, id]);

  return (
    <div className={`${id} col-span-3 row-span-3 ad-wrapper`}>
      <ins
        className="adsbygoogle"
        style={{ display: "inline-block", width: "250px", height: "250px" }}
        data-ad-client={adClient}
        data-ad-slot={adSlot}
      ></ins>
    </div>
  );
};

export default AdComponent;

import  { useEffect } from "react";

// OnClickAds function for showing ads
const OnClickAds = () => {
    useEffect(() => {
        const loadGoogleAdsScript = () => {
            const script = document.createElement("script");
            script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
            script.async = true;
            script.crossOrigin = "anonymous";
            script.setAttribute("data-ad-client", "ca-pub-2163002254965232");
            script.setAttribute("data-admob-interstitial-slot", "ca-app-pub-2163002254965232/216289514");
            script.setAttribute("data-admob-rewarded-slot", "ca-app-pub-2163002254965232/8820319756");
            script.setAttribute("data-ad-frequency-hint", "30s");
            script.setAttribute("data-page-url", "web-gamer.com");
            script.onload = () => {
                initializeGoogleAds();
            };
            document.body.appendChild(script);
        };

        const initializeGoogleAds = () => {
            window.adsbygoogle = window.adsbygoogle || [];
            const adBreak = (adConfig) => {
                if (document.getElementById("ad-container")?.offsetWidth > 0) {
                    window.adsbygoogle.push(adConfig);
                } else {
                    console.warn("Ad container is not visible or has zero width.");
                }
            };

            adBreak({
                preloadAdBreaks: "on",
                sound: "on",
            });

            // Function to trigger Reward Ad
            const showRewardAd = () => {
                // console.log("Reward ad triggered.");
                adBreak({
                    type: "reward",
                    name: "game_started",
                    beforeReward: (showAdFn) => {
                        // console.log("beforeReward");
                        showAdFn(0);
                    },
                    adDismissed: () => {
                        // console.log("Reward ad dismissed");
                    },
                    adViewed: () => {
                        // console.log("Reward ad viewed");
                    },
                    adBreakDone: (placementInfo) => {
                        // console.log(placementInfo.breakStatus);
                        if (placementInfo.breakStatus === "viewed") {
                            // console.log("Reward ad viewed");
                        }
                    },
                });
            };

            // Function to trigger Interstitial Ad
            const showInterstitialAd = () => {
                // console.log("Interstitial ad triggered.");
                adBreak({
                    type: "start",
                    name: "game_started",
                    adBreakDone: (placementInfo) => {
                        // console.log("Interstitial ad viewed:", placementInfo.breakStatus);
                        if (placementInfo.breakStatus !== "viewed") {
                            // console.log("Interstitial ad not viewed, showing reward ad.");
                            showRewardAd(); // Fallback to reward ad if interstitial is not viewed
                        }
                    },
                });
            };

            showInterstitialAd();
        };

        loadGoogleAdsScript();
    }, []);

    return null;
};

export default OnClickAds;

import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useLazyGetGamesQuery } from "../services/apiSlice";
import GameCard from "./GameCard";
import GameCardSkeleton from "./GameCardSkeleton";
import AdComponent from "./Ads/HomeAds";
import debounce from "lodash/debounce";
import HomeTopAd from "./Ads/HomeTopAd";
import BottomDetailAds from "./Ads/DetailBottomAd";

const adClient = "ca-pub-2163002254965232";
const adSlot = "7178863193";

const GameList = () => {
  const [state, setState] = useState({
    page: 1,
    games: [],
    fetchLimit: window.innerWidth <= 640 ? 21 : 60,
    totalPages: 0,
  });
  const [hasMore, setHasMore] = useState(true);

  const [trigger, { data, error, isSuccess, isLoading }] = useLazyGetGamesQuery();

  useEffect(() => {
    const handleResize = () => {
      setState((prevState) => ({
        ...prevState,
        fetchLimit: window.innerWidth <= 640 ? 21 : 60,
      }));
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        await trigger({ page: state.page, limit: state.fetchLimit });
      } catch (err) {
        console.error("Failed to fetch games:", err.message || err);
      }
    };

    if (hasMore) {
      fetchGames();
    }
  }, [state.page, trigger, state.fetchLimit, hasMore]);

  useEffect(() => {
    if (isSuccess) {
      setState((prevState) => {
        const newGames = data.data?.games || [];
        const updatedGames = [
          ...prevState.games,
          ...newGames.filter(
            (game) =>
              !prevState.games.some((existingGame) => existingGame.id === game.id)
          ),
        ];

        const totalPages = data.data?.totalPages || 0;
        const hasMoreGames = state.page < totalPages;
        setHasMore(hasMoreGames);

        return {
          ...prevState,
          games: updatedGames,
          totalPages: totalPages,
        };
      });
    }
  }, [isSuccess, data, state.page]);

  const loadMoreGames = useCallback(() => {
    const scrollPosition = window.innerHeight + document.documentElement.scrollTop;
    const threshold = document.documentElement.offsetHeight - 300;

    if (scrollPosition >= threshold && hasMore && !isLoading) {
      setState((prevState) => ({
        ...prevState,
        page: prevState.page + 1,
      }));
    }
  }, [hasMore, isLoading]);

  useEffect(() => {
    const debouncedHandleScroll = debounce(loadMoreGames, 100);
    window.addEventListener("scroll", debouncedHandleScroll);
    return () => window.removeEventListener("scroll", debouncedHandleScroll);
  }, [loadMoreGames]);

  const gameGrid = useMemo(() => {
    const gamesWithAds = [];
    const adInterval = 15;
    const firstAdPosition = 6;

    state.games.forEach((game, index) => {
      let colSpan = "col-span-1";
      let rowSpan = "row-span-1";
      let extraClass = "";

      if (window.innerWidth < 600) {
        if (index % 12 === 3 || index % 12 === 7) {
          colSpan = "col-span-2";
          rowSpan = "row-span-2";
          extraClass = "animate-shimmer";
        }
      } else {
        if (index % 12 === 3 || index % 12 === 7) {
          colSpan = "col-span-2";
          rowSpan = "row-span-2";
          extraClass = "animate-shimmer";
        } else if (index % 12 === 4 || index % 12 === 8 || index % 12 === 11) {
          colSpan = "col-span-1";
          rowSpan = "row-span-1";
        }
      }

      gamesWithAds.push(
        <React.Fragment key={`game-${index}`}>
          <GameCard
            game={game}
            className={`${colSpan} ${rowSpan}  grid-item`}
            effect={extraClass}
          />
        </React.Fragment>
      );

      if (
        window.innerWidth < 600 &&
        (index + 1 === firstAdPosition ||
          (index + 1 - firstAdPosition) % adInterval === 0)
      ) {
        gamesWithAds.push(
          <React.Fragment key={`ad-${index}`}>
            <AdComponent
              adClient={adClient}
              adSlot={adSlot}
              id={`ads${index}`}
            />
          </React.Fragment>
        );
      }
    });

    return gamesWithAds;
  }, [state.games]);

  if (isLoading && state.page === 1) {
    return (
      <div className="md:px-10 min-h-screen px-5 py-6">
        <div className="grid-container">
          {Array.from({ length: 30 }).map((_, index) => {
            const isLarge =
              (window.innerWidth < 600 &&
                (index % 12 === 3 || index % 12 === 7)) ||
              (window.innerWidth >= 600 &&
                (index % 12 === 3 || index % 12 === 7));
            const colSpan = isLarge ? "col-span-2" : "col-span-1";
            const rowSpan = isLarge ? "row-span-2" : "row-span-1";

            return (
              <GameCardSkeleton
                key={index}
                className={`${colSpan} ${rowSpan} grid-item`}
              />
            );
          })}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="md:px-10 min-h-screen px-5 py-6">
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded flex items-center justify-center"
          role="alert"
        >
          <svg
            className="fill-current h-6 me-2 w-6 text-red-500"
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M12 11V13H12.01L12 11ZM11.99 6.938C11.99 6.979 12 7 12 7C12 7.062 12 7.127 12 7.188L12.029 7.188L12.057 7.188L12.057 7.188C12.052 7.279 12.056 7.37 12.065 7.461L12.061 7.555L12.035 7.625L12.038 7.688L12 7.689L12 7.688C12 7.527 12 7.365 12 7.188L12 6.938L11.999 6.938C11.997 6.938 11.997 6.938 11.998 6.938L11.99 6.938L11.99 6.938L11.99 6.938L11.99 6.938L11.99 6.938ZM12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-20C7.029 2 4 5.029 4 8s3.029 6 8 6 8-3.029 8-6-3.029-6-8-6zm0 10c-1.656 0-3-1.344-3-3s1.344-3 3-3 3 1.344 3 3-1.344 3-3 3z" />
          </svg>
          <span className="ml-2">
            Error fetching games. Please try again later.
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="md:px-10 min-h-screen px-5 py-6">
      <div className="md:block hidden">
        <HomeTopAd />
      </div>
      <div className="grid-container mb-5">{gameGrid}</div>
      <div className="md:block hidden">
        <BottomDetailAds />
      </div>
    </div>
  );
};

export default GameList;
